import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular-ivy";
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.sentryEnable) {
  Sentry.init({
    dsn: "https://119cf0555ff394a7d36a2a84c220e019@o4505942746136576.ingest.sentry.io/4506502082920448",
    release: "0.9.41",
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled , /^https:\/\/ecoportal\.azurewebsites\.net\//
    tracePropagationTargets: [/^https:\/\/www\.geo-portal\.org\.net\//],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
  Sentry.setTag("projectName", "portal-ecogis");
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((success) => console.log('EcoGis success'))
  .catch((err) => console.error(err));
